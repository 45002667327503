/**
 * 由於 module 裡面的 object 是前後端共用的，所以需要有一個共用的 signal
 * 讓後端的 object 能夠使用前端的 signal ，但又不需要引入前端 @angular/core 的 signal
 * 前端的 $.signal / $.computed 會在 main.ts 裡面被重新指定回 @angular/core
 */

import { Selectable } from './object/selectable';

// 可以指定變數型態是 class ，
// 例如 function a(_class: Type<Selectable>) { }
// a(Shadow)
interface Type<T> extends Function {
  new (...args: any[]): T;
}

// signal 的型態
export interface Signal<V> {
  (): V;
}

// 建立 computed 的參數
export interface CreateComputedOptions<T> {
  equal?: ValueEqualityFn<T>;
}

// 用來比較兩個值是否相等的函數型態
export type ValueEqualityFn<T> = (a: T, b: T) => boolean;

// 可寫的 signal 的型態
export interface WritableSignal<T> extends Signal<T> {
  value?: T;
  set(value: T): void;
  update(updateFn: (value: T) => T): void;
  asReadonly(): Signal<T>;
}

// 建立一個前後端可以共用的 signal｀｀｀
// 注意: 後端使用時無法使用 toObservable
const signal = <V = any>(initialValue: V) => {
  let value = initialValue;

  const signal: WritableSignal<V> = function Signal() {
    return value;
  };

  signal.set = (newValue: V) => {
    value = newValue;
  };

  signal.update = (updater: (value: V) => V) => {
    signal.set(updater(value));
  };

  signal.asReadonly = () => signal;

  return signal;
};

// 建立 computed 的函數
// 注意: 後端使用時並不會在內部變數異動時觸發、也無法使用 toObservable
const computed = <T = any>(
  computation: () => T,
  options?: CreateComputedOptions<T>,
) => {
  return computation as Signal<T>;
};

// 將 json 轉換成物件
// - 會將有註冊在 classes 裡面的物件還原(依照 __class__ 的名稱 )
// - 會將 signal 還原(依照 __class__ 的名稱為 SIGNAL)
function fromJSON(json: any, object: any): any {
  const keys = Object.keys(json);

  for (const key of keys) {
    if (json[key] === undefined) continue;

    let jsonValue = json[key] as any;

    // signal
    if (jsonValue instanceof Object && jsonValue.__class__ === 'SIGNAL') {
      jsonValue = jsonValue.value;

      let targetValue = jsonValue;

      if (jsonValue instanceof Array) {
        targetValue = [];
        fromJSON(jsonValue, targetValue);
      } else if (jsonValue instanceof Object) {
        targetValue = {};
        fromJSON(jsonValue, targetValue);
      }

      object[key] = $.signal(targetValue);

      // object
    } else if (jsonValue instanceof Object) {
      if (object[key] === undefined) {
        object[key] = jsonValue instanceof Array ? [] : {};
      }

      if (jsonValue['__class__']) {
        if (classes[jsonValue['__class__']]) {
          object[key] = new classes[jsonValue['__class__']]();
          delete jsonValue['__class__'];
        } else {
          console.warn('class not found', jsonValue['__class__'], jsonValue);
        }
      }

      fromJSON(json[key], object[key]);
    } else {
      object[key] = json[key];
    }
  }

  return object;
}

// 將物件轉換成 json
// - 會將 signal 轉換成 { __class__: 'SIGNAL', value: value }
// - 會將物件轉換成 { __class__: 'ClassName', ... }
// - 忽略設定在 __JSON_IGNORE__ 中的屬性
function toJSON(object: any): any {
  if (object?.set instanceof Function) {
    const value = object();
    return value instanceof Object
      ? { __class__: 'SIGNAL', value: toJSON(value) }
      : { __class__: 'SIGNAL', value };
  } else if (object instanceof Array) {
    return object.map((value) => toJSON(value));
  } else if (object instanceof Object) {
    const plain: any = {};
    Object.entries(classes).forEach(([name, Class]) => {
      if (!(object instanceof Class)) return;
      plain.__class__ = name;
    });

    const jsonIgnore = object.__JSON_IGNORE__
      ? [...object.__JSON_IGNORE__, '__JSON_IGNORE__']
      : [];
    return Object.entries(object).reduce((json, [key, value]) => {
      if (jsonIgnore?.includes(key)) return json;
      json[key] = toJSON(value);
      return json;
    }, plain);
  } else {
    return object;
  }
}

const classes: { [name: string]: typeof Selectable } = {};

// 註冊 class， 在 fromJSON 、 toJSON 時會使用
export function registerClass(name: string, Class: Type<Selectable>) {
  classes[name] = Class;
}

// 將 signal 的值解析出來的型態
export type ResolvedSignal<T> = {
  [P in keyof T]?: T[P] extends Signal<infer V> ? V : ResolvedSignal<T[P]>;
};

export const $ = {
  signal,
  computed,
  toJSON,
  fromJSON,
  SIGNAL: Symbol('SIGNAL'),
  WRITABLE_SIGNAL: Symbol('WRITABLE_SIGNAL'),
};
