import { $, ResolvedSignal, registerClass } from '../../signal';
import { Text } from '../text/text.class';

export class MultipleLineText extends Text {
  public override type = $.signal('MULTIPLE_LINE');

  public letterSpacing = $.signal(0);
  public lineHeight = $.signal(150);

  public constructor(options: ResolvedSignal<MultipleLineText> = {}) {
    super();
    this.initialFromResolvedValue(options);
  }
}

registerClass('MultipleLineText', MultipleLineText);
