import { $ } from '../../signal';
import { Selectable } from '../selectable';

export class Image extends Selectable<Image> {
  public override type = $.signal('IMAGE');

  public image = $.signal('');

  public title = '';
  public width = 0;
  public height = 0;
  public fileFormats: ('png' | 'jpeg')[] = [];

  public defaultImage = '';

  public constructor(options: {
    image: string;
    title: string;
    width: number;
    height: number;
    fileFormats: ('png' | 'jpeg')[];
  }) {
    super();
    this.defaultImage = options.image;
    this.title = options.title;
    this.width = options.width;
    this.height = options.height;
    this.fileFormats = options.fileFormats;
    this.image.set(options.image);
  }
}
