import { MultipleLineText } from '@ay-gosu-party/modules/object/multiple-line-text/multiple-line-text.class';
import { Filmstrip } from '../../abstract/filmstrip.class';
import { FilmstripType } from '../../abstract/filmstrip.type';
import { Background, Text } from '../../object';
import { Font } from '../../object/font/font.class';
import { $ } from '../../signal';

export class CountMarioSection1Question extends Filmstrip {
  public name: string = '問答';

  public override readonly type = FilmstripType.COUNT_MARIO_SECTION_1_QUESTION;

  public override readonly withMobileInteractive = true;

  public projection = {
    background: new Background({
      type: 'IMAGE',
      src: 'assets/count-mario/question.jpg',
    }),
    countdown: {
      enable: true,
      seconds: 30,
      font: new Font(),
    },
    // 右上角的總投票數
    numberOfVotes: new Font({
      align: null,
    }),
    // 每一個選項的字體樣式
    question: new MultipleLineText({
      placeholder: '輸入題目...',
      align: 'center',
    }),
    // 每一個選項得票數的字體樣式
    numberOfVote: new Font({ align: 'center' }),
    option: new Font({ align: 'center' }),
  };

  public mobile = {
    background: new Background({ hex: '#D0D9E4' }),
    question: new Font(),
    option: new Font({
      align: 'center',
      borderRadius: '8px',
      backgroundColor: '#FFFFFF',
      shadow: {
        offsetX: 1,
        offsetY: 1,
        blur: 5,
        color: '#000000',
        opacity: 0.25,
      },
    }),
    submit: new Font({
      align: 'center',
      borderRadius: '4px',
      color: '#FFFFFF',
      backgroundColor: '#303030',
      bolder: true,
    }),
    finishText: new Text({
      content: '您選擇了',
      align: 'center',
    }),
    finishFont: new Font({
      align: 'center',
    }),
  };

  public data = {};

  public override nextButtonName = '公布答案';

  public mobileToolbar = {
    options: ['作答', '作答完畢'],
    selectedOptionIndex: 0,
  };

  public override _processError = $.computed(() => ({
    'projection.background': this.projection.background.error(),
    'projection.countdown.font': this.projection.countdown.font.error(),
    'projection.numberOfVotes': this.projection.numberOfVotes.error(),
    'projection.question': this.projection.question.error(),
    'projection.numberOfVote': this.projection.numberOfVote.error(),
    'projection.option': this.projection.option.error(),
    'mobile.background': this.mobile.background.error(),
    'mobile.question': this.mobile.question.error(),
    'mobile.option': this.mobile.option.error(),
    'mobile.submit': this.mobile.submit.error(),
    'mobile.finishText': this.mobile.finishText.error(),
    'mobile.finishFont': this.mobile.finishFont.error(),
  }));
}
