import { Type } from '@angular/core';
import { Module } from './abstract/module.class';
import { BlankModule } from './blank/blank.class';
import { CountMarioModule } from './count-mario/count-mario.class';
import { DrawCardModule } from './draw-card/draw-card.class';
import { FooterModule } from './footer/footer.class';
import { HeaderModule } from './header/header.class';
import { SignInWallModule } from './sign-in-wall/sign-in-wall.class';
import { SpinnerModule } from './spinner/spinner.class';
import { SurveyModule } from './survey/survey.class';

export function getModuleMap(): Map<
  string,
  Type<Module> & { moduleName: string }
> {
  return new Map<string, Type<Module> & { moduleName: string }>([
    ['SIGN_IN_WALL', SignInWallModule],
    ['COUNT_MARIO', CountMarioModule],
    ['HEADER', HeaderModule],
    ['FOOTER', FooterModule],
    ['SURVEY', SurveyModule],
    ['SPINNER', SpinnerModule],
    ['DRAW_CARD', DrawCardModule],
    ['BLANK', BlankModule],
  ]);
}

export function getModule(
  type: string,
): (Type<Module> & { moduleName: string }) | undefined {
  return getModuleMap().get(type);
}

export function getModules(): Type<Module>[] {
  return [...getModuleMap().values()];
}
