import { $, ResolvedSignal, registerClass } from '../../signal';
import { Selectable } from '../selectable';

export class Background extends Selectable<Background> {
  public override type = $.signal<'COLOR' | 'IMAGE'>('COLOR');

  public hex = $.signal('#FFFFFF');
  public src = $.signal('');

  public constructor(options: ResolvedSignal<Background> = {}) {
    super();
    this.initialFromResolvedValue(options);
  }
}

registerClass('Backstage', Background);
