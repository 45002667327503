import { $, ResolvedSignal, registerClass } from '../../signal';
import { Selectable } from '../selectable';

export class Shadow extends Selectable<Shadow> {
  public override type = $.signal('SHADOW');

  public color = $.signal('#000000');
  public opacity = $.signal(0);
  public offsetX = $.signal(0);
  public offsetY = $.signal(0);
  public blur = $.signal(0);

  public constructor(options: ResolvedSignal<Shadow> = {}) {
    super();
    this.initialFromResolvedValue(options);
  }
}

registerClass('Shadow', Shadow);
