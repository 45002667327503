import { $, ResolvedSignal, registerClass } from '../signal';

export class Selectable<T extends Selectable = Selectable<any>> {
  public __JSON_IGNORE__ = ['error'];

  public type = $.signal('SELECTABLE');

  // 該物件的錯誤內容，會顯示在選擇框的下面，並且標注該投影片為錯誤狀態
  // 格式與 @angular/forms 的 ValidationErrors 一致，如果是 null 代表沒有錯誤
  // 對應的文案顯示於 selectable.directive.ts 中的 _processErrorMessage 中設定
  public error = $.signal<{ [key: string]: any } | null>(null);

  public toJSON() {
    return $.toJSON(this);
  }

  public fromJSON(json: ResolvedSignal<T> = {}) {
    $.fromJSON(json, this);
  }

  public initialFromResolvedValue(resolved: ResolvedSignal<T>) {
    const that = this as any;
    for (const key in resolved) {
      if (that[key]?.initialFromResolvedValue instanceof Function) {
        that[key].initialFromResolvedValue(resolved[key]);
      } else if (that[key]?.set instanceof Function) {
        that[key].set(resolved[key]);
      } else {
        that[key] = resolved[key];
      }
    }
  }
}

registerClass('Selectable', Selectable);
