import { ExcludePick } from '../abstract/exclude-pick';
import { Module } from '../abstract/module.class';
import { ModuleType } from '../abstract/module.type';
import { WinningList } from '../object/winning-list/winning-list.class';
import { $, WritableSignal } from '../signal';
import { CountMarioSection1Question } from './section-1-question/count-mario-section-1-question.class';
import { CountMarioSection2Answer } from './section-2-answer/count-mario-section-2-answer.class';
import { CountMarioSection3Count } from './section-3-count/count-mario-section-3-count.class';
import { CountMarioSection4Winner } from './section-4-winner/count-mario-section-4-winner.class';

export type CountMarioOption = {
  text: string;
  isAnswer: WritableSignal<boolean>;
};

export class CountMarioModule extends Module {
  public static override moduleName = '數隻小瑪莉';

  public name = '數隻小瑪莉';

  public override readonly type = ModuleType.COUNT_MARIO;

  public override filmstrips: [
    CountMarioSection1Question,
    CountMarioSection2Answer,
    CountMarioSection3Count,
    CountMarioSection4Winner,
  ] = [
    new CountMarioSection1Question(),
    new CountMarioSection2Answer(),
    new CountMarioSection3Count(),
    new CountMarioSection4Winner(),
  ];

  public override data = {
    question: $.signal(''),
    options: $.signal<CountMarioOption[]>([
      { text: '選項 A', isAnswer: $.signal(true) },
      { text: '選項 B', isAnswer: $.signal(false) },
      { text: '選項 C', isAnswer: $.signal(false) },
    ]),
    winningList: new WinningList(),
  } as const;

  public override dataError = $.computed(() => ({
    winningList: this.data.winningList.error(),
  }));

  public answer = $.computed(() =>
    this.data
      .options()
      .filter((option) => option.isAnswer())
      .map((option) => option.text)
      .join(' 、 '),
  );

  public constructor(module: Partial<CountMarioModule> = {}) {
    super();
    this.fromJSON(module);
  }
}

export type CountMarioData = ExcludePick<CountMarioModule, Module>;
