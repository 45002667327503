export enum FilmstripType {
  SIGN_IN_WALL = 'SIGN_IN_WALL',
  COUNT_MARIO_SECTION_1_QUESTION = 'COUNT_MARIO_SECTION_1_QUESTION',
  COUNT_MARIO_SECTION_2_ANSWER = 'COUNT_MARIO_SECTION_2_ANSWER',
  COUNT_MARIO_SECTION_3_COUNT = 'COUNT_MARIO_SECTION_3_COUNT',
  COUNT_MARIO_SECTION_4_WINNER = 'COUNT_MARIO_SECTION_4_WINNER',
  FOOTER = 'FOOTER',
  HEADER = 'HEADER',
  SURVEY = 'SURVEY',
  SPINNER = 'SPINNER',
  DRAW_CARD = 'DRAW_CARD',
  BLANK = 'BLANK',
  TOOLBAR = 'TOOLBAR',
}
