import * as uuid from 'uuid';
import { ModuleValidationErrors } from '../error';
import { $, Signal } from '../signal';
import { Filmstrip, FilmstripJson } from './filmstrip.class';
import { ModuleType } from './module.type';

export abstract class Module {
  public static moduleName = 'Module';

  /** 模組是編號 */
  public uuid: string = uuid.v4();

  /** 是否顯示在新增模組中 */
  public showInCreateDialog = true;

  /** 是否屬於其他類別的模組 */
  public showInCreateDialogOther = false;

  /** 固定的模組(不可以複製、刪除、移動、剪下) */
  public readonly isFixed: boolean = false;

  /** 是否隱藏模組 */
  public hidden = $.signal(false);

  /** 該模組額外要儲存的資料 */
  public data: any;

  // 檢查該模組是否有錯誤
  // 會合併所有 filmstrip 的 error 和 dataError
  // 結構會長得像是: filmstrip.0.projection.background
  public error = $.computed(() => {
    const errors: ModuleValidationErrors = {};

    this.filmstrips.forEach((filmstrip, index) => {
      const error = filmstrip.error();
      if (!error) return;

      Object.entries(error).forEach(([key, value]) => {
        if (!value) return;
        errors[`filmstrip.${index}.${key}`] = value;
      });
    });

    const dataError = this.dataError();
    if (dataError) {
      Object.entries(dataError).forEach(([key, value]) => {
        if (!value) return;
        errors[`data.${key}`] = value;
      });
    }

    if (Object.keys(errors).length === 0) return null;

    return errors;
  });

  /** 模組名稱 */
  public abstract name: string;

  /** 模組類型，後台用來載入相對應的編輯頁面用 */
  public abstract readonly type: ModuleType;

  /** 該模組的所有幻燈片 */
  public abstract readonly filmstrips: Filmstrip[];

  /** 檢查該模組的額外資料錯誤 */
  public abstract dataError: Signal<ModuleValidationErrors>;

  public toJSON(): ModuleJson<FilmstripJson[]> {
    return {
      uuid: this.uuid,
      name: this.name,
      type: this.type,
      hidden: this.hidden(),
      filmstrips: this.filmstrips.map((filmstrip) => filmstrip.toJSON()),
      data: $.toJSON(this.data),
    };
  }

  public fromJSON(json: any) {
    if (json.uuid) {
      this.uuid = json.uuid;
    }

    if (json.name) {
      this.name = json.name;
    }

    if (json.hidden !== undefined) {
      this.hidden.set(json.hidden);
    }

    if (json.filmstrips) {
      json.filmstrips.map((filmstrip: any, index: number) => {
        this.filmstrips[index].fromJSON(filmstrip);
        this.filmstrips[index].module = this;
      });
    }

    if (json.data) {
      $.fromJSON(json.data, this.data);
    }

    this.filmstrips.forEach((filmstrip) => {
      filmstrip.module = this;
    });
  }
}

export type ModuleJson<
  FILMSTRIPS extends FilmstripJson[] = FilmstripJson[],
  DATA = any,
> = {
  uuid: string;
  name: string;
  type: string;
  hidden: boolean;
  filmstrips: FILMSTRIPS;
  data: DATA;
};
