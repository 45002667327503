import { Filmstrip } from '../../abstract/filmstrip.class';
import { FilmstripType } from '../../abstract/filmstrip.type';
import { Background } from '../../object';
import { MultipleLineText } from '../../object/multiple-line-text/multiple-line-text.class';
import { Text } from '../../object/text/text.class';
import { $ } from '../../signal';

export class CountMarioSection4Winner extends Filmstrip {
  public name = '公佈得獎者';

  public override readonly type = FilmstripType.COUNT_MARIO_SECTION_4_WINNER;

  public override readonly withMobileInteractive = true;

  public projection = {
    background: new Background({
      type: 'IMAGE',
      src: 'assets/count-mario/winner.jpg',
    }),
  };

  public mobile = {
    background: new Background({ hex: '#D0D9E4' }),
    title: new Text({ content: '恭喜中獎！', align: 'center', bolder: true }),
    card: {
      image: $.signal(
        'https://gosubarpublic.blob.core.windows.net/static/2023/12/05/3111/upload/164657_68df956c-8f18-4420-8233-4f9415b249e8.jpg',
      ),
      useDefaultImage: $.signal(true),
      title: new Text({ content: '恭喜中獎', bolder: true }),
      content: new MultipleLineText({
        content: '請前往櫃檯服務處，兌換禮物！',
      }),
    },
    noAwardText: new Text({ content: '未中獎', align: 'center', bolder: true }),
  };

  public data = {};

  public mobileToolbar = {
    options: ['中獎', '未中獎'],
    selectedOptionIndex: 0,
  };

  public override _processError = $.computed(() => ({
    'projection.background': this.projection.background.error(),
    'mobile.background': this.mobile.background.error(),
    'mobile.title': this.mobile.title.error(),
    'mobile.card.title': this.mobile.card.title.error(),
    'mobile.card.content': this.mobile.card.content.error(),
    'mobile.noAwardText': this.mobile.noAwardText.error(),
  }));
}
